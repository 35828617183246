import React, { createContext, useEffect, useMemo, useReducer } from "react";
import RolesAndPermissions  from './constants/rolesAndPermissions.json';
import moment from "moment";
import { getPermissions } from "../services/controltable";

// console.log('path ==> ', process.env);


const initialAppState = JSON.parse(
  localStorage.getItem("app-provider") || "null"
) || {
  login: null,
};

export const AppContext = createContext(initialAppState);

export default function AppProvider({ children }) {
  const appReducer = (state, action) => {
    switch (action.type) {
      case "SET_PERMISSIONS": 
      return {
        ...state,
        permissions: action.payload
      }
      case "UPDATE_LOGIN": {
        return {
          ...state,
          login: { ...(state.login || {}), ...action.payload },
          expiry: moment().add(moment.duration(3, 'h')).unix()
        };
      }
      case "CLEAR_LOGIN": {
        return {
          ...state,
          login: null,
          expiry: null,
          permissions: {}
        };
      }
      default:
        return state;
    }
  };

  useEffect(() => {
    async function fetchPermissions(){
      const {resData: {data= []}} = await getPermissions();
      const map = data?.[0]?.dictionary;
      const normalizedMap = {}
      for (let key in map){
        normalizedMap[key] = map[key].map(url => `/${url}`)
      }
      dispatch({type: 'SET_PERMISSIONS', payload: normalizedMap})
      // console.log('per', normalizedMap)
    }
    fetchPermissions();
  },[])
  const [state, dispatch] = useReducer(appReducer, initialAppState);

  const availablePages = useMemo(() => {
    let roles = [];
    const accessRoles = decodeURIComponent(
      (state.login && state.login["custom:UclaPersonRole"]) || ""
    )
      .replaceAll("urn:mace:ucla.edu:role:facet-integ-dashboard:", "")
      .replaceAll("[", '')
      .replaceAll("]", '')
      .split(", ");

    
      // const accessPermissions = envRolesNPermissions
      //   .replaceAll("[", '')
      //   .replaceAll("]", '')
      //   .split(", ").map(key => `/${key}`);

    if (accessRoles.length > 0 ) {
      roles = roles.concat(["", "/"])
      accessRoles.forEach((role) => {
       if(role){
        roles = roles.concat({ [role]: state.permissions?.[role] || []})
        if(state.permissions?.[role])
       roles =  roles.concat(state.permissions?.[role] )
       }
      });
    }
    return [...new Set(roles)];
  }, [state.login,state.permissions]);

  useEffect(() => {
    localStorage.setItem("app-provider", JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider
      value={{ data: state, update: dispatch, availablePages }}
    >
      {children}
    </AppContext.Provider>
  );
}
