import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import styles from "./index.module.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Stack,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import CustomField from "../AdminForms/CustomField";
import CustomModal from "../AdminForms/CustomModal";
import { AppContext } from "../../common/AppProvider";
import {
  createSafiBarFundCodes,
  getSafiBarFundCodes,
  updateSafiBarFundCodes,
} from "../../services/safibarflagsTable";
import moment from "moment";

const TOAST_CONFIG = {
  SUCCESS: {
    title: "Success",
    backgroundColor: "#274f82",
    color: "white",
  },
  FAILED: {
    title: "Failed",
    backgroundColor: "#274f82",
    color: "white",
    msgColor: "red",
  },
};

export const SafiBar = () => {
  const { data } = useContext(AppContext);

  const columns: Array<CustomColumn> = [
    {
      name: "BAR_SUB_CODE",
      label: "Bar Sub Code",
      cellClass: `${styles.uid1_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 40,
    },
    {
      name: "SFP_FUND_CODE",
      label: "SFP Fund Code",
      cellClass: `${styles.updateddate_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 40,
    },
    {
      name: "SFP_FUND_NAME",
      label: "Bar Sub Code Description",
      cellClass: `${styles.academicyear_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 120,
    },
    {
      name: "ACTIVE",
      label: "Active",
      cellClass: `${styles.flagvalue_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 40,
    },
    {
      name: "updated_at",
      label: "Last Updated",
      cellClass: `${styles.updateddate_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 100,
    },
    {
      name: "Actions",
      label: "Actions",
      headerClass: `text-center`,
      renderRow: (_, row, idx) => rowActions(row, idx),
      width: 70,
    },
  ];

  const ref = useRef();

  const [showModal, setShowModal] = useState(null);
  const intialdata = {
    BAR_SUB_CODE: "57660",
    SFP_FUND_NAME: "California Department",
    ACTIVE: "Y",
    SFP_FUND_CODE: "60020",
  };
  const [currData, setCurrData] = useState<any>({});
  const [currIndex, setCurrIndex] = useState(null);
  const [toast, setToast] = useState({
    mode: "",
    msg: "",
  });
  const [barfunddata, setBarFundData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchSafiBarFundCodeTable() {
    setIsLoading(true);
    const data = await getSafiBarFundCodes();
    if (data.resData?.data) {
      setBarFundData(data.resData.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSafiBarFundCodeTable();
    const data = [];
  }, []);

  const handleCurrValChange = (e) => {
    setCurrData((prev) => {
      prev[e.target.name] = e.target.value;
      return { ...prev };
    });
  };

  const onEditRow = (index, row) => {
    setCurrData((prev) => ({
      ...prev,
      ...row,
      index,
    }));
    setCurrIndex(index);
    setShowModal("Edit");
  };

  const handleAddRow = () => {
    // setCurrData(() => ({
    //   ...intialdata,
    // }));

    setShowModal("Add");
  };

  const rowActions = useCallback((row, index) => {
    return (
      <Stack className="flex-row justify-content-center" gap={2}>
        <Button
          title="Edit Row"
          size="sm"
          onClick={() => onEditRow(index, row)}
          className="bg-transparent border-0"
        >
          <PencilIcon color="grey" width={18} />
        </Button>
      </Stack>
    );
  }, []);

  //toDo: add all logic here to make update and add calls.
  const onModalClose = () => {
    setCurrIndex(null);
    setShowModal(null);
    setCurrData({})
  };

  const onModalSave = useCallback(async () => {
    try{
    console.log(currData);
    if (showModal === "Edit") {
      if (currIndex === null) {
        throw new Error("Missing edited row index!");
      }
      // setBarFundData(
      //   barfunddata.map((data, index) => {
      //     if (index === currData.index) {
      //       return currData;
      //     }
      //     return data;
      //   })
      // );
      const payload: any = {
        ...currData,
        updated_by: data?.login["custom:displayName"],
        updated_at: moment().toISOString(),
      };
      console.log(payload);
      delete payload.modified;
      delete payload.isNewRow;
  
        await updateSafiBarFundCodes(payload);
        setBarFundData((prev) => {
          prev[currIndex] = { ...currData, modified: true } as any;
          return [...prev];
        });
        await fetchSafiBarFundCodeTable();
        console.log(currData.BAR_SUB_CODE);
        setToast({
          mode: "SUCCESS",
          msg: `SAFI Bar sub code ${currData.BAR_SUB_CODE} saved successfully!`,
        });
    } else {
      setShowModal(null);
      const payload: any = {
        ...currData,
        created_at: moment().toISOString(),
        updated_at: moment().toISOString(),
      };
      console.log(payload);
      delete payload.modified;
      delete payload.isNewRow;
       const resp = await createSafiBarFundCodes(JSON.stringify(payload));
        setBarFundData((prev: any) => {
          return [{ ...currData, isNewRow: true }, ...prev];
        });
          setToast({
            msg:  resp.status >= 400 ? resp.message : `SAFI Bar sub code ${currData.BAR_SUB_CODE} created successfully!`,
            mode: resp.status >= 400 ? "FAILED" :"SUCCESS",
          });
      
      await fetchSafiBarFundCodeTable();
    }
    onModalClose();
    } catch (err) {
      console.error(err);
      onModalClose();
    }
  }, [currData, currIndex, showModal]);

  // const updateRules = async () => {
  //   const { index, ...payload } = currData;
  //   try {
  //     await updateSafiBarFundCodes({
  //       updated_by: data?.login["custom:displayName"],
  //       updated_at: moment().toISOString(),
  //       ...payload,
  //     });
  //     setToast(`SAFI Bar Fund codes saved successfully!`);
  //     await fetchSafiBarFundCodeTable();
  //   } catch (err) {
  //     setToast(`Error occured`);
  //   }
  // };

  return (
    <div className="safi-bar-table relative">
      <Col className="relative">
        <Row>
          <CustomTable
            parentClass={styles.fullTableWidth as any}
            isLoading={isLoading}
            rows={barfunddata || []}
            columns={columns}
            bordered
            ref={ref}
            rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
            rowClass="test"
            responsive
            hasTableActions={true}
            tableActionProps={{
              hasAddRow: false,
              hasSearch: true,
              hasDownload: false,
            }}
            striped
            title={null}
            style={{ tableLayout: "fixed" }}
          />
        </Row>
      </Col>
      <div
        className="d-flex justify-content-end align-items-center"
        style={{ gap: "10px", flex: 1 }}
      >
        <Button size="sm" onClick={handleAddRow}>
          Add
        </Button>
        {/* <Button size="sm" onClick={updateRules}>
          Save
        </Button> */}
      </div>
      <CustomModal
        title={`${showModal} BAR to SFP Fund Code Mapping`}
        show={!!showModal}
        onClose={onModalClose}
        onSave={onModalSave}
        primaryBtnText={`${showModal === "Edit" ? "Update" : "Add"}`}
      >
        <Form>
          <Stack className="mb-3" gap={3}>
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Bar Sub Code"
              name="BAR_SUB_CODE"
              type="text"
              value={currData["BAR_SUB_CODE"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="SFP Fund Code"
              name="SFP_FUND_CODE"
              type="text"
              value={currData["SFP_FUND_CODE"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="SFP Fund Name"
              name="SFP_FUND_NAME"
              type="text"
              value={currData["SFP_FUND_NAME"]}
              required
            />
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Active"
              name="ACTIVE"
              type="select"
              hasDefault
              options={[
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" },
              ]}
              value={currData["ACTIVE"]}
              required
            />
          </Stack>
        </Form>
      </CustomModal>
      <div>
        <ToastContainer position="top-center">
          <Toast show={!!toast?.mode} onClose={() => setToast(null)}>
            <Toast.Header style={{ ...TOAST_CONFIG[toast?.mode] }}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">
                {TOAST_CONFIG?.[toast?.mode]?.title || "TITLE"}
              </strong>
              {/* <strong className="me-auto">Failed</strong> */}
              <small></small>
            </Toast.Header>
            <Toast.Body
              style={{ color: TOAST_CONFIG?.[toast?.mode]?.msgColor }}
            >
              {toast?.msg}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </div>
  );
};
