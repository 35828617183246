import { refetchToken } from "../services/tokenService";


const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
    let [resource, config ] = args;
    const resp  = await originalFetch(resource, config)
    if(!resp.ok){
      const data = await resp.json()
      if([400, 401, 403].includes(resp.status)){
        refetchToken()
      } else {
        return Promise.reject(data)
      }
    }
    return resp
   /* try {
      let data = await originalFetch(resource, config)
    // response interceptor here
   
    if ([400, 401, 403].includes(data.status)) {
      refetchToken()
    } else
    return data;
    } catch(err) {
      if (err.message === "Failed to fetch") {
        refetchToken()
      }
    }
    // request interceptor here
   
   
    const response = await originalFetch(resource, config);
    // response interceptor here
    console.log('response ==> ', response);
    if (response.status === 401 || response.status === 400) {
      refetchToken()
    } else
    return response; */
};

export const http = {
  get,
  post,
  put,
  delete: _delete,
  getXML,
  getXMLID,
};

const headers = {
  "x-api-key": process.env.REACT_APP_API_KEY,
  "Content-Type": "application/json",
  //  "Access-Control-Allow-Credentials": true,
  //  "Access-Control-Allow-Origin": window.location.origin
};

function get(url, customHeaders = {}) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...headers,
      ...customHeaders,
    },
  };
  return fetch(url, requestOptions)
  .then(handleResponse)
  .catch((err) => ({ data: null, error: new Error(err) }));
  // return fetch(url, requestOptions).then(handleResponse);
}

function getXML(url, customHeaders = {}) {
  const requestOptions = {
    method: "GET",
    headers: {
      ...headers,
      ...customHeaders,
      "Content-Type": "application/text",
    },
  };
  return fetch(url, requestOptions).then((response) => response.text())
  .catch((err) => ({ data: null, error: new Error(err) }));
  //.then(text => new window.DOMParser().parseFromString(text, "text/xml"));
}

function getXMLID(url, customHeaders = {},body) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...headers,
      ...customHeaders,
      "Content-Type": "application/text",
    },
  };
  return fetch(url, requestOptions).then((handleResponse) => handleResponse.text())
  .catch((err) => ({ data: null, error: new Error(err) }));
  //.then(text => new window.DOMParser().parseFromString(text, "text/xml"));
}

function post(url, customHeaders = {}, body) {
  const requestOptions = {
    method: "POST",
    headers: { ...headers, ...customHeaders },
    body: body, //JSON.stringify(body),
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch((err) => {
      console.log('errc',err)
      return { data: null, status: err.resCode, ...err }
    });
}

function put(url, customHeaders = {}, body) {
  const requestOptions = {
    method: "PUT",
    headers: { ...headers, ...customHeaders },
    body: body,
  };
  return fetch(url, requestOptions)
  .then(handleResponse)
  .catch((err) => ({ data: null, error: new Error(err) }));
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
  };
  return fetch(url, requestOptions).then(handleResponse)
 .catch((err) => ({ data: null, error: new Error(err) }));
}

// helper functions

async function handleResponse(response) {

  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
