
import { http } from "../common/http";

const getToken = () => {
     return localStorage.getItem("token");
  // return sessionStorage.getItem("token");
};

export const getSafiBarFundCodes = async () => {
  const token = getToken();
  let response;
  try {
    response = await http.get(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}barsfpfundcode`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (err) {
    console.error(err);
  }
  return response;
};


export const createSafiBarFundCodes = async (body) => {
  const token = getToken();
  return http.post(
    `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}barsfpfundcode/create`,
    {
      Authorization: `Bearer ${token}`,
    },
    body
  );
};

export const updateSafiBarFundCodes = async (body) => {
  const token = getToken();
  try {
    return await http.put(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}barsfpfundcode/update`,
      {
        Authorization: `Bearer ${token}`,
      },
      JSON.stringify(body)
    );
  } catch (err) {
    console.error(err);
  }
};





